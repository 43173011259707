@import './variables';

@mixin input {
    color: $DK_GRAY;
    padding: 10px;
    border: 1px solid $GRAY;
    background-color: $LT_GRAY;
    border-radius: $border-radius;
    font-size: 16px;
    max-height: $input-height;
}

@mixin text-input {
    padding: 10px;
    border-radius: $border-radius;
    font-size: 12px;
    max-height: $input-height;
    width: var(--text-input-width);
    background-color: $GRAY_MD;
    border: 0;
    color: $WHITE;
    box-sizing: border-box;
    &.light {
        background-color: $WHITE_30P;
        color: $BLACK;
    }
}

@mixin console-component-bg {
    background-color: $GRAY_DK;
    border-radius: $border-radius;
}

@mixin map-component-bg {
    @include transparent_gray_dk(.9);
    border-radius: $border-radius;
}

@mixin overlay-component-bg {
    background-color: $ALMOST_BLACK;
}

@mixin transparent_gray_dk($opacity) {
    background-color: rgba(38, 38, 38, $opacity);
}

@mixin close-icon($top, $right) {
    position: absolute;
    top: $top;
    right: $right;
    font-size: 32px;
    color: $WHITE;
    cursor: pointer;
}

/*-- if font awesome doesn't have the icon in a circle format, this can be used to mimic it --*/
@mixin mimicCircleFontIcon($font-size) {
    height: calc(2 * #{$font-size});
    width: calc(2 * #{$font-size});
    font-size: $font-size;
    border-radius: 50%;
}

@mixin backIcon {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: $font-size-20;
    z-index: 1;
    cursor: pointer;
}

@mixin text-link {
    font-weight: $font-weight-400;
    color: $BLUE;
    background: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

@mixin sub-text {
    font-weight: $font-weight-300;
    font-size: 10px;
}

@mixin primaryButton {
    color: $WHITE;
    background-color: $WHITE_15P;
    &:hover {
        background-color: $WHITE_30P;
    }
}

@mixin white-button {
    color: $GRAY_DK;
    background-color: $WHITE;
}

@mixin gray-dk-button {
    color: $WHITE;
    background-color: $GRAY_DK;
}

@mixin gray-button {
    color: $WHITE;
    background-color: $GRAY;
}

@mixin gray-lt-button {
    color: $WHITE;
    background-color: $GRAY_LT;
}

@mixin gray-xlt-button {
    color: $WHITE;
    background-color: $GRAY_XLT;
}

@mixin transparent-dk-button {
    color: $WHITE;
    @include transparent_gray_dk(.9);
}

@mixin microsoft-red-button {
    color: $WHITE;
    background-color: $MICROSOFT_RED;
}

@mixin blue-lt-button {
    color: $WHITE;
    background-color: $LT_BLUE;
}

@mixin red-button {
    color: $WHITE;
    background-color: $RED;
}

@mixin green-bright-button {
    color: $WHITE;
    background-color: $BRIGHT_GREEN;
}

@mixin red-bright-button {
    color: $WHITE;
    background-color: $BRIGHT_RED;
}

@mixin text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin text-multi-line-ellipsis($numberOfLines) {
    display: -webkit-box;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $numberOfLines;
}

@mixin autofill-fix($font-size, $letter-spacing) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $WHITE !important;
        transition-delay: 9999s !important;
        transition: background-color 9999s ease-in-out 0s !important;
        &:-webkit-autofill::first-line {
            font-family: 'Gilmer', sans-serif;
            color: $WHITE;
            font-size: $font-size !important;
            letter-spacing: $letter-spacing !important;
        }
    }
}

@mixin login-logo-background {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 60%;
    max-width: 1000px;
    height: 100%;
    background: url(/assets/img/logo-horizontal.png) no-repeat center center;
    background-size: contain;
    opacity: 0.05;
    pointer-events: none;
    filter: brightness(0) invert(1);
}

@mixin weavix-white-logo {
    max-width: 260px;
    background: url(/assets/img/logo-horizontal.png) no-repeat center center;
    width: 100%;
    height: 92px;
    margin: 20px;
    background-size: contain;
    filter: brightness(0) invert(1);
}

@mixin login-material-overwrites {
    ::ng-deep .mat-form-field-should-float .mat-form-field-label {
        transform: translateY(1.3em) !important;
        color: $WHITE !important;
        opacity: 0.5;
    }
    ::ng-deep .mat-error {
        color: $WHITE !important;
    }
}

@mixin iconBadge($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit, $border: $LT_GRAY) {
    position: absolute;
    width: $badge-size;
    height: $badge-size;
    background: $backgroundColor;
    border-radius: $border-circle;
    border: 1px solid $border;
    z-index: $z100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $color;
    font-size: $font-size;
}

// standard top right placement
@mixin notificationBadge($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit, $border: $LT_GRAY) {
    @include iconBadge($badge-size, $color, $backgroundColor, $font-size, $border);
    top: 0px;
    right: calc(#{$badge-size} / -3);
}

// mid right placement
@mixin midNotificationBadge($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit, $border: $LT_GRAY) {
    @include iconBadge($badge-size, $color, $backgroundColor, $font-size, $border);
    bottom: calc(#{$badge-size} / 0.7);
    right: calc(#{$badge-size} / -1.7);
}

@mixin mapPinBadgeTopLeft($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit) {
    @include iconBadge($badge-size, $color, $backgroundColor, $font-size);
    bottom: auto;
    right: 7px;
    top: -18px;
}

@mixin mapPinBadgeTopRight($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit) {
    @include iconBadge($badge-size, $color, $backgroundColor, $font-size);
    bottom: auto;
    right: -30px;
    top: -18px;
}

@mixin mapPinBadgeBottomRight($badge-size: 10px, $color: $DK_GRAY, $backgroundColor: $RED, $font-size: inherit) {
    @include iconBadge($badge-size, $color, $backgroundColor, $font-size);
    right: -40px;
    bottom: -24px;
}

@mixin noDataText($text-align: center) {
    width: 100%;
    color: $GRAY;
    text-align: $text-align;
    font-style: italic;
    font-weight: $font-weight-400;
}

@mixin validationError {
    color: $RED;
    padding: 5px 10px;
    height: 30px;
}

@mixin login-button($margin){
    width: 100%;
    background-color: $WHITE_15P;
    padding: 45px 10px;
    color: $WHITE;
    border: none;
    font-size: 18pt;
    margin: $margin;
    &:hover {
        background-color: $WHITE_30P;
    }
}

@mixin login-input($font-size, $letter-spacing) {
    width: 100%;
    background-color: $WHITE_15P !important;
    padding: 70px 10px 40px 10px;
    color: $WHITE;
    border: none;
    font-size: $font-size;
    font-weight: 400;
    letter-spacing: $letter-spacing;
    &:hover {
        background-color: $WHITE_30P;
    }
    &::placeholder {
        color: $WHITE_65P;
    }
    @include autofill-fix($font-size, $letter-spacing);
}

@mixin phone-input($font-size, $letter-spacing) {
    @include login-input($font-size, $letter-spacing);
    padding-left: 60px;
}

@mixin autofill-fix($font-size, $letter-spacing) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $WHITE !important;
        transition-delay: 9999s !important;
        transition: background-color 9999s ease-in-out 0s !important;
        &:-webkit-autofill::first-line {
            font-family: 'Gilmer', sans-serif;
            color: $WHITE;
            font-size: $font-size !important;
            letter-spacing: $letter-spacing !important;
        }
    }
}

@mixin filters-container {
    display: flex;
    flex-flow: column nowrap;
    width: $CONSOLE_SIDEBAR_WIDTH;
    margin-left: $page-inner-spacing;
}

@mixin filter-container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    @include console-component-bg;
}

@mixin filter-inner-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 15px;
    &.light {
        color: $BLACK;
    }
}

@mixin header-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--header-icon-font-size);
    width: var(--header-icon-size);
    height: var(--header-icon-size);
    margin: 1px 8px;
    background-color: $WHITE_30P;
    border-radius: 50%;
    line-height: 1.8em;
    transition: 0.4s;
    color: $WHITE;
    cursor: pointer;
    
    &:hover {
        background-color: $WHITE;
        color: $BLACK;
    }
}

@mixin header-action-button {
    height: 100%;
    background-color: $LT_BLUE;
    font-size: $font-size-12;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
}

@mixin header-action-icon {
    height: 20px;
    width: 20px;
    background-color: $WHITE;
    color: $LT_BLUE;
    border-radius: $border-circle;
    margin-right: 10px;
    font-size: $font-size-10;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin header-action-input {
    height: 100%;
    max-height: 100%;
    background-color: $WHITE !important;
    color: $BLACK !important;
    font-size: $font-size-12;
    font-weight: 300;

    &::placeholder {
        font-style: normal !important;
        color: $BLACK !important;
        font-size: $font-size-12;
        font-weight: 300;
    }
}

// attributes-style action buttons
@mixin attributes-style-actions {
    width: 70px;
    height: 35px;
    display: flex;
    gap: 10px;
    padding: 0 10px;

    .button {
        padding: 0;
        &.add {
            color: $LT_BLUE;

            &:disabled {
                color: $WHITE_45P;
            }
        }
    }
}

// animations

@mixin button-ripple-out($color, $name) {
    @-webkit-keyframes #{$name} {
        0% {
            box-shadow: 0 4px 10px $color, 0 0 0 0 $color, 0 0 0 5px $color, 0 0 0 10px $color;
        }
        100% {
            box-shadow: 0 4px 10px $color, 0 0 0 5px $color, 0 0 0 10px $color, 0 0 0 20px $color;
        }
    }
    @keyframes #{$name} {
        0% {
            box-shadow: 0 4px 10px $color, 0 0 0 0 $color, 0 0 0 5px $color, 0 0 0 10px $color;
        }
        100% {
            box-shadow: 0 4px 10px $color, 0 0 0 5px $color, 0 0 0 10px $color, 0 0 0 20px $color;
        }
    }
}

// helps prevent UI flickers when updating a transform/transitions
@mixin prevent-transform-flicker {
    -webkit-backface-visibility: hidden!important;
    -webkit-transform-style: preserve-3d!important;
}

@mixin required-fields-instructions {
    margin-bottom: 20px;
    font-size: $font-size-12;
    &::before {
        content: '*';
        color: $W_RED;
        margin-right: 2px;
    }
}

@mixin vertical-center-position-absolute {
    top: 50%;
    transform: translateY(-50%);
}

@mixin breakpoint($class) {
    @if $class == $sm { @media (min-width: $sm) { @content; } }
    @else if $class == $med { @media (min-width: $med) { @content; } }
    @else if $class == $lg { @media (min-width: $lg) { @content; } }
    @else if $class == $xl { @media (min-width: $xl) { @content; } }
    @else { @warn "Breakpoint mixin supports: xs, sm, md, lg"; }
}

@mixin map-filter-container {
    display: flex;
    flex-flow: column nowrap;
    width: $CONSOLE_SIDEBAR_WIDTH;
    margin-left: 20px;
    @include console-component-bg;
}
